import { Box, useTheme } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import RewardSidebar from "src/components/SideContainer/rewardSidebar";
import CommunitySidebar from "src/components/SideContainer/communitySidebar";
export const AccentHeaderLayout = (props) => {
  const theme = useTheme();
  return (
    <>
      <Header />
      <Sidebar pageThoughtsHeading={props.pageThoughts?.heading} pageThoughtsTxt={props.pageThoughts?.txt} />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pl: `${parseInt(theme.sidebar.width) + 15}px`,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${theme.header.height});`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>{props.children}</Box>
        </Box>
      </Box>
    </>
  );
};

export const MainAppLayout = (props) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1} sx={{}}>
            {props.children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const MainAppLayoutWithSidebar = (props) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
        }}
      >
        <Box sx={{ display: "flex", flex: 1, flexDirection: "column", width: "100%" }}>
          <Box flexGrow={1} sx={{}}>
            <Box className="rewards-section">
              {/* add new box for flex */}
              <Box sx={{}} display="flex" justifyContent="space-between">
                {/* add sidebar container and box */}
                <Box width={250} className="scrollbar-hide" sx={{ borderRight: "1px solid black", padding: "40px 20px", height:"calc(100vh - 165px)", overflowY: "auto" }}>
                  
                  {
                    window.location.pathname.includes('/rewards/community') || window.location.pathname.includes('/collections') ? 
                    <CommunitySidebar />
                    :
                    <RewardSidebar />
                  }
                </Box>
                {props.children}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const PreLoginLayout = (props) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          overflowY: "auto",
          maxHeight: `calc(100vh - ${theme.header.height});`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>
            {props.children}
          </Box>

          <Box component="footer" mt="82px">
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};
