import React from "react";
import { TableCell, TableRow, Typography, Button, Checkbox, styled, Box, Tooltip, Link } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility"
import { formatNum, trimStr, viewDateTime } from "src/utils";
import { shortEthAddress, shortNum } from "src/utils/ethereum";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

export const DropTableRow = (props) => {
  const { rowData } = props;

  const DeleteButton = styled(Button)(
    ({ theme }) => `
      &:hover{
        background:none;
      }
  `
  );
  const EditButton = styled(Button)(
    ({ theme }) => `
      &:hover{
        background:none;
      }
  `
  );

  return (
    <TableRow key="1" sx={{ "&:last-child td, &:last-child th": { border: 0 } }} className="collection-row">
      <TableCell component="th" scope="row" width={500}>
        <Typography variant="h5" component="h2" alignItems={"center"} display="flex">
          {rowData.logo_image == null ? <img src="/assets/images/default_collection.svg" className="collection-image" alt="" /> : <img src={rowData.logo_image} className="collection-image" width="70" alt="" />}
          <Tooltip title={rowData.title.length > 30 ? rowData.title : ""}>
            <Typography variant="h5" className="collection-name" pl={1}>
              {trimStr(rowData.title, 30)}
              <br />
              {props.collectionType == "admin" && (
                <>
                  {rowData.contract_address}
                  <br />
                  {rowData.chain_id}
                </>
              )}
              {rowData.contract_type == "ERC1155" && (
                <Box variant="span" sx={{ wordBreak: "break-all" }}>
                  #{shortNum(rowData.token_id)}
                </Box>
              )}
            </Typography>
          </Tooltip>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="h3" className="collection-size">
          {" "}
          {rowData.total_supply == null ? "N/A" : formatNum(rowData.total_supply)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className="collection-description" title={rowData.details != null && rowData.details.length > 78 ? rowData.details : ""}>
          {" "}
          {rowData.details === null || "" ? "n/a" : rowData.details.length > 78 ? rowData.details.substr(0, 78) + "..." : rowData.details}
        </Typography>
      </TableCell>
      <TableCell>
        {(() => {
          if (rowData.chain_id == 1 || rowData.chain_id == 4) {
            return (
              <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif", display: "flex", justifyContent: "center" }} component="h3">
                <img src="/assets/images/eth.png" width="24" alt="Eth" />
              </Typography>
            );
          } else if (rowData.chain_id == 43113 || rowData.chain_id == 43114) {
            return (
              <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif", display: "flex", justifyContent: "center" }} component="h3">
                <img src="/assets/images/avalanche.svg" width="24" alt="Avax" />
              </Typography>
            );
          } else if ([84531, 11155111, 8453].includes(parseInt(rowData.chain_id))) {
            return (
              <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif", display: "flex", justifyContent: "center" }} component="h3">
                <img src="/assets/images/base-network.png" width="24" alt="Base" />
              </Typography>
            );
          } else {
            return (
              <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif", display: "flex", justifyContent: "center" }} component="h3">
                <img src="/assets/images/primary.svg" width="24" alt="Poly" />
              </Typography>
            );
          }
        })()}
      </TableCell>
      <TableCell>
        {props.collectionType == "admin" ? (
          <EditButton
            onClick={() => {
              props.ShowEditModal(rowData.id);
            }}
            className="action-icon"
          >
            <EditIcon />
          </EditButton>
        ) : (
          <DeleteButton
            onClick={() => {
              props.onClick(rowData.id);
            }}
            className="action-icon"
          >
            <DeleteOutlineIcon sx={{ pl: 1.5 }} />
          </DeleteButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export const DropTableRowSm = (props) => {
  const handleCheckboxClick = (idv, e) => {
    if (e.target.checked) {
      props.setSponsor("add", props.collection);
    } else {
      props.setSponsor("remove", props.collection);
    }
  };

  const handleRowclick = async (e, id) => {
    let element = document.getElementById(id);
    if (e.target != element) {
      await element.click();
    }
  };

  return (
    <TableRow
      key={props.drop.key}
      sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
      onClick={(e) => {
        handleRowclick(e, `collection_${props.drop.key}`);
      }}
      className="collection-table"
    >
      <TableCell component="th" scope="row" className="table-row">
        <Tooltip title={props.drop.title.length > 25 ? props.drop.title : ""}>
          <Typography variant="h5" component="h2" alignItems={"center"} display="flex" className="collection-name">
            <Checkbox
              {...label}
              disableRipple
              icon={<img src="/assets/img/Uncheck.svg" width="18" alt="" />}
              checkedIcon={<img src="/assets/img/Checkbox.svg" width="18" alt="" />}
              key={`chkk_${props.drop.key}`}
              id={`collection_${props.drop.key}`}
              checked={props.sponsors.filter((s) => s.id == props.collection.id).length > 0}
              onClick={(e) => {
                handleCheckboxClick(`collection_${props.drop.key}`, e);
              }}
            />
            {props.drop.img == null ? <img src="/assets/images/default_collection.svg" className="collection-image" alt="" htmlFor={`collection_${props.drop.key}`} /> : <img src={props.drop.img} className="collection-image" alt="" htmlFor={`collection_${props.drop.key}`} />}
            <Box display="flex" flexDirection="column">
              {trimStr(props.drop.title, 25)}
              {props.collection.contract_type == "ERC1155" && (
                <Box variant="span" sx={{ wordBreak: "break-all" }}>
                  #{shortNum(props.collection.token_id)}
                </Box>
              )}
            </Box>
          </Typography>
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="h3" className="collection-size" component="h3">
          {props.drop.size == null ? "N/A" : props.drop.size}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" sx={{ fontFamily: "roboto, sans-serif" }}>
          {(() => {
            if (props.drop.chain_id == 1 || props.drop.chain_id == 4) {
              return (
                <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif" }} component="h3">
                  <img src="/assets/images/eth.png" width="25" alt="Eth" />
                </Typography>
              );
            } else if (props.drop.chain_id == 43113 || props.drop.chain_id == 43114) {
              return (
                <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif" }} component="h3">
                  <img src="/assets/images/avalanche.svg" width="25" alt="Avax" />
                </Typography>
              );
            } else if ([84531, 11155111, 8453].includes(parseInt(props.drop.chain_id))) {
              return (
                <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif", display: "flex", justifyContent: "center" }} component="h3">
                  <img src="/assets/images/base-network.png" width="24" alt="Base" />
                </Typography>
              );
            } else {
              return (
                <Typography variant="h3" align="center" sx={{ fontFamily: "roboto, sans-serif" }} component="h3">
                  <img src="/assets/images/primary.svg" width="25" alt="Poly" />
                </Typography>
              );
            }
          })()}
        </Typography>
      </TableCell>
    </TableRow>
  );
};

export const UsersTableRow = (props) => {
  const { rowData } = props;

  const DeleteButton = styled(Button)(
    ({ theme }) => `
      &:hover{
        background:none;
      }
  `
  );
  const EditButton = styled(Button)(
    ({ theme }) => `
      &:hover{
        background:none;
      }
  `
  );
  const label = { inputProps: { "aria-label": `Switch ${rowData.id}` } };
  const paidLabel = { inputProps: { "aria-label": `User Paid ${rowData.id}` } };
  return (
    <TableRow key={rowData.id} sx={{ "&.MuiTableRow-root .MuiTableCell-root:first-child": {borderLeft: props.edit ? 'inherit' : '0px solid'}, "&.MuiTableRow-root .MuiTableCell-root:last-child": {borderRight: props.edit ? 'inherit' : '0px solid'}, "&:last-child td, &:last-child th": { border: 0 } }} className="collection-row">
      <TableCell component="th" scope="row" width={500}>
        <Box display="flex" flexDirection="column">
          <Box component="span">{`${rowData.fname} ${rowData.lname}`}</Box>
          <Box component="span">{rowData.full_brand_domain}</Box>
        </Box>
      </TableCell>
      <TableCell>{rowData.email}</TableCell>
      <TableCell>{shortEthAddress(rowData.eth_address)}</TableCell>
      <TableCell sx={{"&::first-letter": {textTransform: 'capitalize'}}}>
        <Box component="strong">{rowData.partner_type}</Box><br/>{rowData.partner_program}
      </TableCell>
      <TableCell sx={{"&::first-letter": {textTransform: 'capitalize'}}}>
        <Box component="strong">{viewDateTime(rowData.createdAt)}</Box>
      </TableCell>
      <TableCell key={`cell_membership_setting_${rowData.id}`}>
        <Switch key={`membership_setting_${rowData.id}`} checked={rowData?.other_data?.allow_membership_settings || false} id={`tog_membership_setting_${rowData.id}`} onChange={(e) => props.handleMembershipToggle(rowData.id, e.target.checked)} {...label} disableRipple />
      </TableCell>
      <TableCell key={`cell_paid_setting_${rowData.id}`}>
        <Switch key={`paid_setting_${rowData.id}`} checked={rowData?.is_paid || false} id={`tog_ispaid_setting_${rowData.id}`} onChange={(e) => props.handlePaidAccToggle(rowData.id, e.target.checked)} {...paidLabel} disableRipple />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {
            true &&
          <Box component="span" sx={{cursor: 'pointer'}} onClick={() => {
            props.ShowViewModal(rowData.id)
          }}><VisibilityIcon /></Box>
          }
          <Box component="span" sx={{cursor: 'pointer', ml: 1.5}}
            onClick={() => {
              props.ShowEditModal(rowData.id);
            }}
            className="action-icon"
          >
            <EditIcon />
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};


export const PartnersTableRow = (props) => {
  const { rowData } = props;


  return (
    <TableRow key="1" sx={{ "&.MuiTableRow-root .MuiTableCell-root:first-child": {borderLeft: props.edit ? 'inherit' : '0px solid'}, "&.MuiTableRow-root .MuiTableCell-root:last-child": {borderRight: props.edit ? 'inherit' : '0px solid'}, "&:last-child td, &:last-child th": { border: 0 } }} className="collection-row">
      <TableCell component="th" scope="row" width={500}>
        <Typography variant="h5" component="h2" alignItems={"center"} display="flex">
          {rowData.brand_logo == null ? <img src="/assets/images/default_collection.svg" className="collection-image" alt="" /> : <img src={rowData.brand_logo} className="collection-image" width="70" alt="" />}
          <Link href={rowData.full_brand_domain} target="_blank" sx={{'&:hover': {textDecoration: 'none'}}}>
            <Typography variant="h5" className="collection-name" pl={1}>
              {rowData.membership_nft_details?.contract_name}
            </Typography>
          </Link>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant={props.edit ? "h3" : "span"} className="collection-size">
          {" "}
          {rowData.email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className="collection-description" >
          <Typography variant="h6" className="collection-name" pl={1}>{rowData.rewards_created}</Typography>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" className="collection-description" >
          <Typography variant="h6" className="collection-name" pl={1}>{rowData.rewards_claimed}</Typography>
        </Typography>
      </TableCell>
      {
        props.edit &&
      <TableCell>
        <Box component="span" sx={{cursor: 'pointer'}} onClick={() => {
            props.showEditModal(rowData.id);
          }}><EditIcon /></Box>
      </TableCell>
      }
    </TableRow>
  );
};