import React, { useEffect, useState } from 'react';
import { Box,Typography,TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemovePollChoice from '../Modals/remove_poll_choice';


function Poll(props) {
    const [deleteModal, setDeleteModal] = useState(false);
    const {dropData,handleChildData, nextTabErrors,setNextTabErrors} = props;
    const [choices, setChoices] = useState(props?.dropData?.poll?.choices);
    const [question, setQuestion] = useState(props?.dropData?.poll?.question);
    const [addComment, setAddComment] = useState(props?.dropData?.poll?.allow_comment)
    const [choiceItem, setChoiceItem] = useState('');

    const addBox = () => {
        if(choices.length > 7){
            return
        }else{
            setChoices(prev => {
                return [...prev, '']
            })
        }
    }

    const removeBox = (index) => {
        if (dropData.status === 1) {
            setChoiceItem(index);
            setDeleteModal(true);
        } else {
            let newChoices = [...choices];
            newChoices = newChoices.filter((c, ind) => ind !== index);
            setChoices(newChoices);
        }
    }

    const handleChoiceChange = (value, index) => {
        let changedChoice = [...choices];
        changedChoice[index] = value

        setChoices(changedChoice)
    }

    useEffect(() => {
        let data = {
            question,
            choices,
            allow_comment: addComment
        }

        handleChildData('poll', data)
    }, [question, choices,addComment])



  return (
    <Box className='poll-form'>
        {dropData.status !== 1 &&
            <>
                <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
                <Typography variant="h1" textAlign="center" sx={{mb: 3}} className="title">Add your poll information</Typography>
            </>
        }

        <Box mt={2}>
            <Typography variant='h4' className='label-text'>Question</Typography>

            <TextField fullWidth margin="normal" error={nextTabErrors.poll_question.error != ''} helperTexts={nextTabErrors.poll_question.error} helperText={<Box display="flex" justifyContent={"space-between"}>
              <Box>
                {nextTabErrors.poll_question.error == "" ? '' : nextTabErrors.poll_question.error}
              </Box>
              {question.length > 0 ? `${question.length} / 50` : false}
            </Box>
            } value={question} onChange={(e) => { setQuestion(e.target.value); setNextTabErrors({...nextTabErrors,['poll_question']:{value: typeof e.target.value=="string" ?  String(e.target.value).trim() : e.target.value,error:""}})}} FormHelperTextProps={{ style: { textAlign: (nextTabErrors.poll_question.error !== "") ? 'left' : 'right',color: (question.length == 50 || nextTabErrors.poll_question.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} label={<span>Question &nbsp;<span style={{color:'red'}}>*</span></span>} placeholder='What do you want to know?' name="question" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 50 }} />

            <Typography variant='h4' className='label-text' mt={2}>Choices</Typography>

            {choices.map((choice, index) => {
                return(
                    index < 2 ?
                    <>
                    {/* {JSON.stringify(props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0])} */}
                    <TextField sx={{mb:2}} error={props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != "" && props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != null} helperText={
                        <Box display="flex" justifyContent={"space-between"}>
                        <Box>
                            {props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error == '' ? '' : props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error}
                            </Box>
                            {choice.length > 0 ? `${choice.length} / 80` : false}
                        </Box>
                     } FormHelperTextProps={{ style: { textAlign: (props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != "") ? 'left' : 'right', color: (choice.length == 80 || props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }}

                     fullWidth margin="normal"   label={<span>Choice {index + 1} &nbsp;<span style={{color:'red'}}>*</span></span>} placeholder={`Enter a choice.`} name={`choice${index+1}`} variant="outlined" value={choice} onChange={(e) => {
                        handleChoiceChange(e.target.value, index);
                        setNextTabErrors({...nextTabErrors,['poll_choices']:
                        nextTabErrors.poll_choices.map((ch, i) => {
                            if(i == index) {
                                return {choice: {value: e.target.value, error: ""}}
                            }else{
                                return ch
                            }
                        })
                        });

                    }} InputLabelProps={{shrink: true}} inputProps={{ maxLength: 80 }} />
                    </>
                    :
                    <Box display="flex" alignItems="start" mb={2}>
                        <TextField fullWidth margin="normal" error={props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != "" && props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error != null} helperText={props.nextTabErrors?.poll_choices?.filter((tab_err, ind) => ind == index)[0]?.choice?.error} label={<span>Choice {index + 1} &nbsp;<span style={{color:'red'}}>*</span></span>} placeholder={`Enter a choice.`} name={`choice${index+1}`} variant="outlined" value={choice} onChange={(e) => handleChoiceChange(e.target.value, index)} InputLabelProps={{shrink: true}} inputProps={{ maxLength: 255 }} />
                        <Button className='delete-choice-btn' sx={{ml:1, p:0, mt:"1rem"}} onClick={() => removeBox(index)} size='small'><img alt="" src="/assets/img/delete-choice.svg" /></Button>

                    </Box>
                )
            })}


            {choices.length < 8 &&
                <Box textAlign="center">
                    <Typography variant="body1" mb={1}>Click the "+" to add a new choice (up to 8)</Typography>
                    <Button sx={{borderRadius: '50%', height:'60px', border: '1px dashed #1A75FF',fontWeight: '400', fontSize: '2.5rem', color: '#1A75FF',p:0}} display="flex" justifycontent="center" alignitems='center' onClick={() => addBox()}><AddIcon sx={{fontSize: '100%'}}/></Button>
                </Box>
            }
            <FormControlLabel 
            sx={{"& .MuiFormControlLabel-label": {opacity: '1'}, mt: "5px"}} control={<Checkbox checked={addComment} onClick={(e) => {
                setAddComment(e.target.checked)
                console.log("Clicked!")
            }} defaultChecked={addComment}  disableRipple icon={<img src="/assets/img/Uncheck.svg" width="18" alt="" />} checkedIcon={<img src="/assets/img/Checkbox.svg" width="18" alt="" />}  />} label={`Let ${dropData.community_type === "membership" ? 'members' : 'claimers'} add a comment (it will not shown to the public.)`} />
        </Box>
        {deleteModal &&
          <RemovePollChoice open={deleteModal} handleClose={() => setDeleteModal(false)} setChoices={setChoices} choices={choices} choiceItem={choiceItem} />
        }
    </Box>
  )
}

export default Poll