import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Box, Typography, Button, Container, Card,  CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,Paper,Alert,Snackbar, Skeleton, styled, Link } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from "@mui/icons-material/Edit";

import ContactUsCta from 'src/components/ContactUsCta';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NewPartnerForm from 'src/components/Modals/partners/new';
import EditPartnerForm from 'src/components/Modals/partners/edit';
import { memberPartnersList } from 'src/services/actions';
import { PartnersTableRow } from 'src/components/DropItem/tablerow';




function SkeletonUserData() {
  
  return (
    <>
      <TableCell sx={{display:'flex' , alignItems:'center'}}>
        <Skeleton variant="rectangular" width={70} height={70} />
        <Skeleton variant="rectangular" width={93} height={20} sx={{marginLeft:'14px'}} />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
      <TableCell>
      <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
    </>
  )
}


const Partners = (props) => {
  const [open, setOpen] = useState(false);
  const [editPartnerId, setEditPartnerId] = useState('')
  const [showSnackbar , setShowSnackbar] = useState(false)
  const [allowEdit, setAllowEdit] = useState(false)
  const { user, partners } =props

  const vertical =  'top';
  const horizontal = 'center';

  useEffect(() => {
    props.memberPartnersList()
  }, [])

  const showEditModal = (i) => {
    setAllowEdit(true)
    setEditPartnerId(i)
  }
  
  return (
    <>
        <Snackbar
          sx={{zIndex: '7 !important'}}
            anchorOrigin={{ vertical, horizontal }}
            open={showSnackbar}
            autoHideDuration={5000}
            key={vertical + horizontal}
          >
          <Alert severity="info" sx={{ width: '100%' }}>
            It may take a while
          </Alert>
        </Snackbar>
    <Box sx={{mx:11}} className='collections'>
      <Box sx={{pb: 3,pt:5, mb:7}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' className='title'>Partners</Typography>
        </Box>

        <Box>
          <Button variant="contained" onClick={() => {setOpen(true);}} className='btn'>
            <AddCircleOutlineIcon />&nbsp;Add New Partner
          </Button>
        </Box>
      </Box>     
      <Box sx={{ ml:3}} >
      {(partners.data.length === 0) ?
        partners.type =='request' ? 
        <Card className='table-card' sx={{maxWidth: '1100px !important'}}>
        <CardContent>
          <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
            <Container>
            <Table className='table' aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={200} className='table-head'>Name</TableCell>
                  <TableCell width={200} className='table-head'>Email</TableCell>
                  <TableCell width={250} className='table-head'>Rewards Created</TableCell>
                  <TableCell width={250} className='table-head'>Rewards Claimed</TableCell>
                  <TableCell width={50} className='table-head'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className='collection-row'>
                <SkeletonUserData/>
                </TableRow>
              </TableBody>
            </Table>

            </Container>
          </TableContainer> 
        </CardContent>
        </Card>
        :
        <Box>
          
          <Box textAlign="center">
            <ContactUsCta />
          </Box>
        </Box>
        :
        <Card className='table-card' sx={{maxWidth: '1100px !important'}}>
          <CardContent>
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
              <Container>
                <Table className='table' aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={200} className='table-head'>Name</TableCell>
                      <TableCell width={200} className='table-head'>Email</TableCell>
                      <TableCell width={250} className='table-head'>Rewards Created</TableCell>
                      <TableCell width={250} className='table-head'>Rewards Claimed</TableCell>
                      <TableCell width={50} className='table-head'></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {partners.data.map((item,index)=> {
                      return(
                        <PartnersTableRow showEditModal={showEditModal} edit={true} key={item.id} rowData={item} />
                      )
                    })}
                  </TableBody>
                </Table>
              </Container>
            </TableContainer> 
          </CardContent>
        </Card>
        }
      </Box>
      { open &&   
      <NewPartnerForm open={open} handleClose={() => setOpen(false)}  />
      }
      {allowEdit &&
      <EditPartnerForm partnerUser={partners.data.filter(item => item.id === editPartnerId)[0]} open={allowEdit} handleClose={() => setAllowEdit(false)} />
      }
    </Box>
    </>
  )
}



const mapStateToProps = (state) => ({
  user: state.user,
  partners: state.partners
})

const mapDispatchToProps = {
  memberPartnersList
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners)