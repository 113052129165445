import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, ImageList, ImageListItem, Link } from "@mui/material";
import UserBoxModal from "./UserBox";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import CategoryModal from "../Modals/category";
import VideoDropdown from "./Drop_Down";
import BasicMenu from "./Drop_Down";
import { connect } from "react-redux";
import { useEffect } from "react";

const RewardSidebar = (props) => {
  const [categoryModalOpen, setCategoryModalOpen] = useState(false)
  const [categoryActionType, setCategoryActionType] = useState(""); 
  const [allowedToAdd, setAllowedToAdd] = useState(false); 
  const [valueData, setValueData] = useState({name: "", title: "", id: "", slug: ""})

  const navigate = useNavigate()
  // const [showModal, setShowModal] = useState(false);
  const [listDatas, setListDatas] = useState([
    {
      imgs: "/assets/images/rewards.svg",
      name: "Rewards",
      title: "",
      href: "/rewards/community",
      alt: "rewards",
      haveDropdown: false
    },
  ]);
  

  const returnArrayFromCat = (data) => {
    let array = [];

    for (let i = 0; i < data.length; i++) {
      array.push({
        id: data[i].id,
        imgs: "/assets/images/recipes.svg",
        name: data[i].name,
        title: data[i].title,
        href: `/category/${data[i].slug}`,
        alt: data[i].name,
        haveDropdown: true
      })
    }

    return [...listDatas, ...array]
  }

  const datalistsec = [
    {
      imgs: "/assets/images/membership.svg",
      title: "Community Page",
      href: `${props.user.data.full_brand_domain}/c`,
      newTab: true,
    },
    {
      imgs: "/assets/images/manage_collections.svg",
      title: "Manage Collections",
      href: "/collections",
      newTab: false,
    },
    {
      imgs: "/assets/images/setting.svg",
      title: "Settings",
      href: "/settings",
      newTab: false,
    },
  ];

  const menuClicked = (data) => {
    setCategoryActionType("edit")
    setValueData({
      ...data
    })
    setCategoryModalOpen(true)
    // console.log("Id", data)
  }

  useEffect(() => {
    let flag = false;
    if (props.user.data.membership_program && props.user.data.membership_program?.role === 'admin') {
      flag = true;
    } else if (props.user.data?.other_data?.allow_membership_settings && props.user.data.membership_program && props.user.data.membership_program?.role === 'other') {
      flag = false;
    } else  {
      flag = true;
    }
    
    setAllowedToAdd(flag);
  },[props.user.data])


  return (
    <>
      <Box display="flex" justifyContent="space-between" flexDirection="column" height="calc(99vh - 80px)">
        <Box>
          <List sx={{ width: "100%", p: 0, borderBottom: "1px dashed black", paddingBottom: "10px", }} component="nav" aria-labelledby="nested-list-subheader">
            <ListItemButton className="sidebar_focused" sx={{ marginBottom: "10px", padding: "5px 10px", height: "37px", display: "flex", justifyContent: "space-between", alignItems: "center", background: window.location.pathname == "/rewards/community" ? "#F3F3F3" : "", "&:hover": { color: "#000", backgroundColor: "#F3F3F3", fontWeight: "700", fontSize: "20px", cursor: "pointer"}, ".MuiListItemText-root .MuiTypography-root": { fontFamily: window.location.pathname == "/rewards/community" ? "General Sans bold" : "General Sans Medium"}}} onClick={() => { navigate("/rewards/community") }}>
              <Box sx={{ display: "flex", alignItems: "center" }} className="sidebar_focused">
                <Box sx={{ width: "20px", height: "20px", marginRight: "10px" }}>
                  <img src={"/assets/images/rewards.svg"} alt={"rewards"} loading="lazy" />
                </Box>

                <ListItemText sx={{ "span": { fontFamily: "General Sans Medium", fontWeight: "500", fontSize: "20px", cursor: "pointer", color: "black", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "130px" } }}>Rewards</ListItemText>
              </Box>
            </ListItemButton>
          </List>

          <List sx={{ width: "100%", p: 0, marginTop: "20px" }} component="nav" aria-labelledby="nested-list-subheader">
            {datalistsec.map((v, i) => (
              <ListItemButton key={i} className="sidebar_focused" sx={{ marginBottom: "10px", padding: "5px 10px", height: "37px", display: "flex", justifyContent: "space-between", alignItems: "center", background: window.location.pathname == v.href ? "#F3F3F3" : "", "&:hover": { color: "#000", backgroundColor: "#F3F3F3", fontWeight: "700", fontSize: "20px", cursor: "pointer" }, ".MuiListItemText-root .MuiTypography-root": { fontFamily: window.location.pathname == v.href ? "General Sans bold" : "General Sans Medium" } }} onClick={() => { v.newTab ? window.open(v.href, '_blank') : navigate(v.href) }}>
                <Box sx={{ width: "auto", height: "20.5px", marginRight: "10px" }}>
                  <img src={`${v.imgs}?w=248&fit=crop&auto=format`} alt={v.title} loading="lazy" />
                </Box>

                <ListItemText sx={ { "span": { fontFamily: "General Sans Medium", fontWeight: "500", fontSize: "20px", cursor: "pointer", color: "black" } }}>
                  {v.title}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Box>
        <UserBoxModal />
      </Box>
    </>
  );
};


const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RewardSidebar)