import React,{useEffect, useState} from 'react'
import { Box, Typography, Button, Container, Card,  CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,Paper,Alert,Snackbar, Skeleton, styled, Link, Modal, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { membershipUsersListById } from 'src/services/actions';
import { PartnersTableRow } from '../DropItem/tablerow';
import ContactUsCta from '../ContactUsCta';
import { shortEthAddress } from 'src/utils/ethereum';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  height:"650px",
  overflow:"auto"
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function SkeletonUserData() {
  
  return (
    <>
      <TableCell sx={{display:'flex' , alignItems:'center'}}>
        <Skeleton variant="rectangular" width={70} height={70} />
        <Skeleton variant="rectangular" width={93} height={20} sx={{marginLeft:'14px'}} />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
      <TableCell>
          <Skeleton variant="rectangular" width={50} height={20}  />
      </TableCell>
    </>
  )
}

const ViewUserModal = (props) => {
  const { open, handleClose, user_data } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [members, setMembers] = useState({type: '', data: [], message: ''})

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = members.data.filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );


  useEffect(() => {
    setMembers({...members, type: 'request'})
    props.membershipUsersListById(user_data.id, (res) => {
      if (res.type === 'success') {
        setMembers({data: res.data, type: 'success', message: ''})
      } else {
        setMembers({data: [], type: 'error', message: res.message})
      }
    })
  }, []);


  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="Edit Form Field" sx={{outline: 'none'}} onBackdropClick={()=>{return false}}>
      <Box sx={style}>
        <Box>
          <Typography variant="h4" >{`${user_data.fname} ${user_data.lname}`} ({user_data.email})</Typography>
          <Typography variant="h3" sx={{mb: 1}}>Membership Users</Typography>
        </Box>
        <Box mt={0}>
          <TextField sx={{width: '40%', mb: '17px'}} name="search_fields" value={searchQuery} onChange={handleSearch} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Search' inputProps={{ maxLength: 60 }}  />
        {(members.data.length === 0) ?
          members.type =='request' ? 
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, pt: 1, pb:5}}>
              <Table className='table' aria-label="simple table">
                <TableHead>
                  <TableRow sx={{"&.MuiTableRow-root .MuiTableCell-root:first-child": {borderLeft: '0px solid'}, "&.MuiTableRow-root .MuiTableCell-root:last-child": {borderRight: '0px solid'}}}>
                    <TableCell width={200} className='table-head' sx={{borderLeft: '0px solid'}}>Name</TableCell>
                    <TableCell width={200} className='table-head'>Email</TableCell>
                    <TableCell width={250} className='table-head'>Eth Address</TableCell>
                    <TableCell width={250} className='table-head'sx={{borderRight: '0px solid'}}>Rewards Claimed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className='collection-row'>
                    <SkeletonUserData/>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          :
          <Box>
            <Box textAlign="center">
              <Typography variant='h4'>No records found</Typography>
            </Box>
          </Box>
          :
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, pt: 1, pb:5}}>
              <Table className='table' aria-label="simple table">
                <TableHead>
                  <TableRow sx={{"&.MuiTableRow-root .MuiTableCell-root:first-child": {borderLeft: '0px solid'}, "&.MuiTableRow-root .MuiTableCell-root:last-child": {borderRight: '0px solid'}}}>
                    <TableCell width={200} className='table-head'>Name</TableCell>
                    <TableCell width={200} className='table-head'>Email</TableCell>
                    <TableCell width={250} className='table-head'>Eth Address</TableCell>
                    <TableCell width={250} className='table-head'>Rewards Claimed</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody >
                  {filteredData.map((item,index)=> {
                    return(
                      <TableRow key={item.id} className="collection-row" sx={{ "&.MuiTableRow-root .MuiTableCell-root:first-child": {borderLeft: '0px solid'}, "&.MuiTableRow-root .MuiTableCell-root:last-child": {borderRight: '0px solid'}, "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row" width={500}>
                          {item.u_fname || item.u_lname ? 
                            <Box component="span">{`${item.u_fname} ${item.u_lname}`}</Box>
                            :
                            ""
                          }
                        </TableCell>
                        <TableCell>{item.email}</TableCell>
                        <TableCell>{shortEthAddress(item.eth_address)}</TableCell>
                        <TableCell>{item.rewards_claimed}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      </Box>

    </Modal>
  )
}

const mapStateToProps = (state) => ({
  userUpdate: state.userUpdate,
  adminUsers: state.adminUsers
})

const mapDispatchToProps = {
  membershipUsersListById
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewUserModal)